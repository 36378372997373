import { scrollToElem } from './scrollToElem';

export const initScrollToAnchor = () => {
    const links = document.querySelectorAll('[data-anchor-link]');
    if (!links.length) return;

    links.forEach((link) => {
        link.addEventListener('click', handleOnClickLink, false);
    });

    function handleOnClickLink(event) {
        event.preventDefault();
        let offsetTop;
        this.hash === '#features' ? (offsetTop = 160) : (offsetTop = -20);

        scrollToElem(this.hash, offsetTop, true);
    }
};
