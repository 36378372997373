/**
 * selector - string. Selector of the item to scroll to
 * offset - number. Indentation from the element to which we scroll. A negative value is longer from to the block.
 * smooth - boolean, optional, default true. Slow or fast scrolling
 *
 * Example: scrollToElem('[data-claim-banner]', 0, false);
 * */

export const scrollToElem = (selector, offset, smooth = true) => {
    const menu = document.querySelector('[data-menu]');
    const target = document.querySelector(selector);
    if (!target) return;
    const options = {
        top: target.offsetTop + offset,
        behavior: smooth ? 'smooth' : 'auto',
    };
    menu.classList.remove('active-menu');
    window.scrollTo(options);
};
