export const initJivoChat = () => {
    if (!Boolean(window.geotrackingAiJivoChatHash)) return;

    // VARS
    const delay = 5000;
    const url = `//code.jivosite.com/widget/${window.geotrackingAiJivoChatHash}`;
    const script = document.createElement('script');

    // INITS
    script.src = url;
    script.setAttribute('async', '');

    setTimeout(() => {
        document.head.appendChild(script);
    }, delay);
};
